<template>
  <section>
    <div
      :style="backgroundStyle"
      :class="{ 'max-lg:overflow-hidden': data.table_of_content }"
      class="relative"
    >
      <UiLazyImg
        v-if="data.background_type === RichTextBackgroundTypes.IMAGE"
        class="h-dynamic-image-mobile w-full lg:h-dynamic-image"
        :class="[data.height_desktop ? '' : 'absolute inset-0 h-full']"
        :src="data.file?.url || ''"
        :src-mobile="data.fileMobile?.url || ''"
        :alt="data.file?.alt || removeHTMLTagFromString(data.title) || ''"
        cover
        :no-lazy="!blockIndex"
        :img-classes="'object-cover'"
        :img-style="`object-position: ${data.image_position || 'center'}`"
        :style="{
          '--image-height': data.height_desktop || '100%',
          '--mobile-image-height':
            data.height_mobile || data.height_desktop || '100%',
        }"
      />
      <div
        class="container"
        :class="[
          data.height_desktop &&
          data.background_type === RichTextBackgroundTypes.IMAGE
            ? 'absolute inset-0 h-full'
            : 'relative',
        ]"
      >
        <h2
          v-if="data.title"
          class="title-underline mb-5 flex justify-center pb-4 text-base font-normal tracking-wider text-gray-disabled"
          v-html="data.title"
        />
        <div v-if="data.table_of_content" class="lg:flex lg:gap-x-20">
          <div
            class="table-of-content w-full lg:max-w-[360px] lg:flex-[0_0_360px]"
            @click="contentLink"
            v-html="data.table_of_content"
          ></div>
          <div
            v-if="data.content"
            class="content lg:grow"
            @click="contentLink"
            v-html="data.content"
          ></div>
        </div>
        <div
          v-else-if="data.content"
          class="content"
          @click="contentLink"
          v-html="isShowMoreLessEnabled ? editedText : data.content"
        ></div>
      </div>
    </div>
    <div v-if="isShowMoreLessEnabled" class="container pb-10 pt-2 lg:pb-16">
      <UiButton
        variant="text"
        class="pl-0 underline"
        @click="showMore = !showMore"
      >
        {{ showMore ? $t("showMore") : $t("showLess") }}
      </UiButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { parse } from "node-html-parser";
import { RichTextBackgroundTypes, RichTextModuleData } from "ecom-types";
const props = withDefaults(
  defineProps<{
    data: RichTextModuleData;
    blockIndex?: number;
    blockName?: string;
  }>(),
  {
    blockIndex: -1,
    blockName: "",
  },
);
const contentLink = useContentLink;
const { isShowMoreLessEnabled, showMore, editedText } = useShowMoreLess();

const backgroundStyle = computed(() => {
  switch (props.data.background_type) {
    case RichTextBackgroundTypes.COLOR: {
      return {
        backgroundColor:
          props.data.color.hexa || props.data.color || "transparent",
      };
    }
    case RichTextBackgroundTypes.CUSTOM: {
      return {
        background: props.data.custom_background,
      };
    }
    default: {
      return {};
    }
  }
});

function useShowMoreLess() {
  const showMore = ref(true);
  const isShowMoreLessEnabled = computed(() => {
    return props.blockName?.includes("show-more-less");
  });
  const editedText = computed(() => {
    return showMore.value
      ? extractContentUpToSecondP(props.data.content)
      : props.data.content;
  });

  function extractContentUpToSecondP(html: string): string {
    const root = parse(html);
    const paragraphs = root.querySelectorAll("p");
    if (paragraphs.length < 2) {
      return html;
    }
    const secondPIndex =
      html.indexOf(paragraphs[1].toString()) + paragraphs[1].toString().length;
    return html.substring(0, secondPIndex);
  }
  return {
    isShowMoreLessEnabled,
    showMore,
    editedText,
  };
}
</script>

<style scoped>
.content:deep(.button) {
  @apply flex max-w-fit items-center justify-center bg-primary px-[25px] py-4 text-sm uppercase text-white outline-0 transition-all hover:bg-neutral-black-800;
}
.content:deep(.button.outline) {
  @apply border !border-primary !bg-transparent !text-primary hover:!bg-primary hover:!text-white;
}

.content:deep(.link-underline) {
  a {
    @apply underline;
  }
}
</style>
